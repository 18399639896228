import theme from 'gatsby-theme-listing/src/gatsby-plugin-theme-ui'

export default {
    ...theme,
    fonts: {
        body: "'Lora', serif",
        heading: "'Merriweather', serif",
        monospace: "'Roboto', sans-serif",
      },
    fontWeights: {
        body: 300,
        heading: 700,
        bold: 700,
    },  
    colors: {
        text: '#222525',
        background: '#fff',
        grey: '#584838',
        primary: '#B782F8',
        secondary: '#00bd6f',
        muted: '#fff2e4',
    },
}